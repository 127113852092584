import { useEffect, useState } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faChartColumn, faChartSimple, faEdit, faExclamationTriangle, faMoneyBill1Wave, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import TableSimple from "../shared/TableSimple";
import InfoModal from "../../InfoModal";
import ConfirmModal from "../../ConfirmModal";
import FormSimple from "../shared/FormSimple";
import { toast } from "react-toastify";
import { deleteMejoraMetrica, newMejoraMetrica, updateMejoraMetrica } from "../../../services/dynamicDashboard";
import PorcentajeForm from "./PorcentajeForm";
import { updateUserPorcentaje } from "../../../services/users";
import { getPercentageAtcClass, setErrorMessage } from "../../../helpers/functionalities";

export default function MejorasMetricas({
    userId,
    metricas,
    userMetrics,
    onActionDone
}) {

    const [currentMejora, setCurrentMejora] = useState(null);
    const [porcentaje, setPorcentaje] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [openFormPorcentaje, setOpenFormPorcentaje] = useState(false);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
    const [porcentajePiezaNoEncontrada, setPorcentajePiezaNoEncontrada] = useState(null);

    useEffect(() => {
    }, [metricas]);

    useEffect(() => {
        userMetrics?.porcentaje_pieza_no_encontrada
            ? setPorcentajePiezaNoEncontrada(userMetrics?.porcentaje_pieza_no_encontrada)
            : setPorcentajePiezaNoEncontrada(0);
    }, [userMetrics]);

    const handleOpenForm = (mejora) => {
        if(mejora) setCurrentMejora(mejora);

        setOpenForm(true);
    }
    const handleCloseForm = () => {
        setCurrentMejora(null);
        setOpenForm(false);
    }

    const handleSubmitMejora = async(data) => {
        let response;

        if(!currentMejora) {
            response = await newMejoraMetrica(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        } else {
            response = await updateMejoraMetrica(data)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });
        }

        if(response && response.success) {
            toast.success(response.message);
            handleCloseForm();
            onActionDone();
        }
    }

    const handleOpenConfirmDelete = (mejora) => {
        setCurrentMejora(mejora);
        setOpenConfirmDelete(true);
    }
    const handleCloseConfirmDelete = () => {
        setCurrentMejora(null);
        setOpenConfirmDelete(false);
    }

    const handleConfirmDelete = async(confirm) => {
        if(confirm) {
            const response = await deleteMejoraMetrica(currentMejora.id)
            .catch(function (error) {
                toast.error(setErrorMessage(error))
            });

            if(response && response.success) {
                toast.success(response.message);
                onActionDone();
            }
        }

        handleCloseConfirmDelete();
    }

    const handleOpenFormPorcentaje = (event, porcentaje) => {
        event.stopPropagation();
        setPorcentaje(porcentaje);
        setOpenFormPorcentaje(true);
    }
    const handleCloseFormPorcentaje = () => {
        setPorcentaje(null);
        setOpenFormPorcentaje(false);
    }

    const handleSubmitPorcentaje = async(data) => {
        const response = await updateUserPorcentaje(data)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
        });

        if(response && response.success) {
            toast.success(response.message);
            setPorcentajePiezaNoEncontrada(data.porcentaje_pieza_no_encontrada);
        }

        handleCloseFormPorcentaje();
    }
  
    return (
        <div className="w-100 d-flex flex-column">
            <div className="w-100 mt-4">
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="mejoras-metricas"
                    >
                        <FontAwesomeIcon icon={faChartColumn} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                        Lista de mejoras de métricas
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className="w-100">
                            { (userId) ?
                                <div className="w-100 d-flex align-items-center justify-content-end mb-4">
                                    <button className="btn btn-add" onClick={() => handleOpenForm()}>
                                        <span className="add">
                                            <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                                        </span>
                                        Nueva mejora de métrica
                                    </button>
                                </div>
                                : null
                            }

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="ratio-conversion-content"
                                    id="ratio-conversion"
                                >
                                    <FontAwesomeIcon icon={faChartSimple} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                                    Ratio de conversión <span className={"fw-bold ms-2 " + getPercentageAtcClass('conversion', userMetrics?.ratio_conversion)}>{userMetrics?.ratio_conversion}%</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableSimple 
                                        id={'mejora-metrica-conversion'}
                                        userId={userId}
                                        label={'mejoras de métricas'}
                                        items={metricas?.filter(m => m.tipo === 'conversion')}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="ratio-beneficio-content"
                                    id="ratio-beneficio"
                                >
                                    <FontAwesomeIcon icon={faMoneyBill1Wave} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                                    Ratio de beneficio <span className={"fw-bold ms-2 " + getPercentageAtcClass('beneficio', userMetrics?.ratio_beneficio)}>{userMetrics?.ratio_beneficio}%</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableSimple 
                                        id={'mejora-metrica-beneficio'}
                                        userId={userId}
                                        label={'mejoras de métricas'}
                                        items={metricas?.filter(m => m.tipo === 'beneficio')}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="ratio-incidencias-content"
                                    id="ratio-incidencias"
                                >
                                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                                    Ratio de incidencias <span className={"fw-bold ms-2 " + getPercentageAtcClass('incidencia', userMetrics?.ratio_incidencias)}>{userMetrics?.ratio_incidencias}%</span>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableSimple 
                                        id={'mejora-metrica-incidencias'}
                                        userId={userId}
                                        label={'mejoras de métricas'}
                                        items={metricas?.filter(m => m.tipo === 'incidencia')}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </AccordionDetails>
                            </Accordion>


                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="ratio-not-found"
                                    id="ratio-not-found"
                                >
                                    <div className="w-100 d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center justify-content-between">
                                            <FontAwesomeIcon icon={faBan} size="1x" color="#215732" className="me-3"></FontAwesomeIcon>
                                            <span>Ratio de pieza no encontrada</span>
                                            <span className={"fw-bold ms-2 " + getPercentageAtcClass('no-encontrada', porcentajePiezaNoEncontrada ? porcentajePiezaNoEncontrada : 0)}>
                                                {porcentajePiezaNoEncontrada ? porcentajePiezaNoEncontrada : 0}%
                                            </span>
                                        </div>

                                        { (userId) ? 
                                            <div>
                                                <span title="Editar ratio de pieza no encontrada" className="action me-2" style={{cursor: 'pointer'}} onClick={(event) => handleOpenFormPorcentaje(event, porcentajePiezaNoEncontrada )}>
                                                    <FontAwesomeIcon icon={faEdit} size="1x" color="#215732" />
                                                </span>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TableSimple 
                                        id={'mejora-metrica-no_encontrada'}
                                        userId={userId}
                                        label={'mejoras de métricas'}
                                        items={metricas?.filter(m => m.tipo === 'no_encontrada')}
                                        onOpenForm={handleOpenForm}
                                        onDelete={handleOpenConfirmDelete}
                                    />
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentMejora ? 'Editar' : 'Nueva') + ' mejora de métrica'}
                    content={
                        <FormSimple 
                            id={'mejoras-metricas'}
                            item={currentMejora}
                            userId={userId}
                            onSubmitItem={handleSubmitMejora}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }

            { (openFormPorcentaje) && 
                <InfoModal
                    state={openFormPorcentaje}
                    title={'Editar porcentaje de pieza no encontrada'}
                    content={
                        <PorcentajeForm 
                            porcentaje={porcentaje}
                            userId={userId}
                            onSubmitItem={handleSubmitPorcentaje}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseFormPorcentaje}
                ></InfoModal>
            }

            { openConfirmDelete && 
                <ConfirmModal 
                    onConfirmAction={handleConfirmDelete} 
                    title={'Eliminar'} 
                    description={'Estás seguro de eliminar?'}
                    state={openConfirmDelete}>
                </ConfirmModal>
            }
        </div>
    )
}